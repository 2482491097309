$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color : white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;

$button-text-color: #fff;

$theme-color-1: #48494b;
$theme-color-2: #999da0;
$theme-color-3: #bebdb8;
$theme-color-4: #60646b;
$theme-color-5: #8996a0;
$theme-color-6: #aaa89c;

$primary-color: #3a3a3a;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$gradient-color-1 : #646568;
$gradient-color-2 : #48494b;
$gradient-color-3 : #4d4d4d;

$lp-bg-color-1 : #48494b;
$lp-bg-color-2 : #999da0;
$lp-bg-color-3 : #60646b;
$lp-bg-color-4 : #bebdb8;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$logoPath: "../../../assets/img/honda_black.svg";
$logoPathMobile: "../../../assets/img/honda_black.svg";

$lpLogoPath: "../../../assets/img/honda_black.svg";
$lpLogoPathPinned: "../../../assets/img/honda_black.svg";

@import "../_mixins.scss";
@import "../_vien.style.scss";
